<template>
  <Teleport to="body">
    <div class="modal videoplayer-popup__root">
      <div class="modal__body ">
        <button
          class="modal__close"
          @click="onClosePopup"
        >
          <img
            src="~/assets/img/svg/common/close-black.svg"
            alt="close icon"
          >
        </button>
        <video
          :src="videoLink"
          controls
          autoplay
          loop
        ></video>
        <div
          class="modal__overlay"
          @click="onClosePopup"
        ></div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useVideoplayerPopup } from '#imports';

const { onCloseVideoplayerPopup, videoplayerState } = useVideoplayerPopup();

const onClosePopup = () => {
  onCloseVideoplayerPopup();
};

const videoLink = computed(() => videoplayerState.value?.videoLink || '');
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.videoplayer-popup__root {
	display: flex;
	align-items: center;
	justify-content: center;

	.modal__body {
		max-width: 1200px;
		height: auto;
		padding: 0;
		background: var(--primary-10);
		border: 2px solid var(--secondary);
	}

	.modal__close {
		width: 18px;
		height: 18px;
		top: 32px;

		& > img {
			filter: invert(91%) sepia(59%) saturate(285%) hue-rotate(183deg) brightness(96%) contrast(90%);
		}
	}

	.modal__overlay {
		background: rgb(52 52 52 / 60%);
		backdrop-filter: blur(16px);
		opacity: 1;
	}
}
</style>
